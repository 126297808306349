import props from '../../common/props';
import request from '../utils/request';

const updateLocaleVars = () => {
    window.Platform.preferredLanguage = props.displayLocale;
    window.Platform.preferredLanguages = [
        ...new Set([
            props.displayLocale,
            props.culture,
            props.selectorLocale,
            'en',
            'en-US',
            'en-WW',
        ]),
    ];
    window.Platform.preferredLanguageDetails = {
        displayLocale: props.displayLocale,
        selectorLocale: props.selectorLocale,
        culture: props.culture,
        twoCharacterLanguageCode: props.twoCharacterLanguageCode,
        twoCharacterRegionCode: props.twoCharacterRegionCode,
        threeCharacterLanguageCode: props.threeCharacterLanguageCode,
        threeCharacterRegionCode: props.threeCharacterRegionCode,
    };
    window.browserInfo = {
        lang_two_letter: props.twoCharacterLanguageCode,
        locale_two_letter: props.twoCharacterRegionCode,
        lang_three_letter: props.threeCharacterLanguageCode,
        locale_three_letter: props.threeCharacterRegionCode,
    };
};

const updateLocaleProps = (localeDetails) => {
    props.displayLocale = localeDetails.displayLocale;
    props.selectorLocale = localeDetails.selectorLocale;
    props.culture = localeDetails.culture;
    props.twoCharacterLanguageCode = localeDetails.twoCharacterLanguageCode;
    props.twoCharacterRegionCode = localeDetails.twoCharacterRegionCode;
    props.threeCharacterLanguageCode = localeDetails.threeCharacterLanguageCode;
    props.threeCharacterRegionCode = localeDetails.threeCharacterRegionCode;

    updateLocaleVars();
};

const updateIframeProps = (localeDetails) => {
    const iframes = document.getElementsByTagName('iframe');
    for (let i = 0; i < iframes.length; i += 1) {
        try {
            if (
                iframes[i]?.contentWindow?.updateLocaleProps
                && typeof iframes[i]?.contentWindow?.updateLocaleProps === 'function'
            ) {
                iframes[i].contentWindow.updateLocaleProps(localeDetails);
            }
        } catch {}
    }
};

const notifyLocaleChanged = () => {
    const windowEvent = new Event('ngplanguagechange');
    window.dispatchEvent(windowEvent);

    if (
        window.onngplanguagechange
        && typeof window.onngplanguagechange === 'function'
    ) {
        window.onngplanguagechange();
    }

    const iframes = document.getElementsByTagName('iframe');
    for (let i = 0; i < iframes.length; i += 1) {
        try {
            const iframeEvent = new Event('ngplanguagechange');
            iframes[i]?.contentWindow?.dispatchEvent(iframeEvent);

            if (
                iframes[i]?.contentWindow?.onngplanguagechange
                && typeof iframes[i]?.contentWindow?.onngplanguagechange === 'function'
            ) {
                iframes[i].contentWindow.onngplanguagechange();
            }
        } catch {}
    }
};

const setLocale = async (locale, persist = false) => {
    const content = await request('/api/locale/set', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            locale,
            persist,
        }),
    });
    const response = await content.json();
    if (
        response
        && response.displayLocale
        && response.selectorLocale
        && response.culture
        && response.twoCharacterLanguageCode
        && response.twoCharacterRegionCode
        && response.threeCharacterLanguageCode
        && response.threeCharacterRegionCode
    ) {
        updateLocaleProps(response);
        updateIframeProps(response);
        notifyLocaleChanged();
    }
};

export { updateLocaleProps, updateLocaleVars, setLocale };
