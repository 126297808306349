export class Registration {
    entity = '';
    channel = -1;
    revision = -1;
    constructor(_entity, _channel, _revision) {
        this.entity = _entity;
        this.channel = _channel;
        this.revision = _revision;
    }
}

export class SPOCPayload {
    registration = [];
    constructor(_registration) {
        this.registration = _registration;
    }
}
