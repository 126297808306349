import { Repositories, SPOCChannel } from '../base/maggi-constants';
import RepositoryMap from '../models/repository-models';

class RepositoryHelper {
    RepositoryToSPOCChannelMap = [];
    constructor() {
        this.RepositoryToSPOCChannelMap = [];
        this.mapRepositoryToSPOCChannels();
    }

    mapRepositoryToSPOCChannels() {
        this.RepositoryToSPOCChannelMap.push(new RepositoryMap(Repositories.devices, [SPOCChannel.SC_DATASTORE_UPDATE, SPOCChannel.SC_REGISTRATION_UPDATE]));
        this.RepositoryToSPOCChannelMap.push(new RepositoryMap(Repositories.messagebox, [SPOCChannel.SC_MTS_MESSAGE]));
        this.RepositoryToSPOCChannelMap.push(new RepositoryMap(Repositories.nof, [
            SPOCChannel.SC_DATASTORE_UPDATE, SPOCChannel.SC_REGISTRATION_UPDATE,
            SPOCChannel.SC_EVENT_LOG_UPDATE, SPOCChannel.SC_REGISTRATION_DELETE,
            SPOCChannel.SC_EVENT_LOG_LOCATION, SPOCChannel.SC_EVENT_LOG_PRIORITY_70,
            SPOCChannel.SC_NF_DATA_REQUEST_ACK, SPOCChannel.SC_NF_OS_ACCOUNT_ADD,
        ]));
        this.RepositoryToSPOCChannelMap.push(new RepositoryMap(Repositories.dashboard, [SPOCChannel.SC_DSP_DASHBOARD_UPDATE]));
    }
}

export default RepositoryHelper;
