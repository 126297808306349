import props, { setSpaRecord } from '../../common/props';
import createLogger from '../logging/ngp-logger';
import request from '../utils/request';

const telemetryLogger = createLogger('ngmpTelemetryLogger');

const setTelemetryIdInSession = (flowName) => {
    request('/api/telemetry/startflow', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(flowName),
    })
        .then((response) => {
            if (response.ok) {
                response.text().then((telemetryId) => {
                    setSpaRecord({ ...props.spaRecord, ...{ NLokTraceId: telemetryId } });
                });
            }
        })
        .catch((response) => {
            telemetryLogger.info(
                `startFlow error, failed to set NLokTraceId in session; errorCode=${response.status}, errorText=${response.statusText}`,
            );
        });
};

const deleteTelemetryIdInSession = () => {
    request('/api/telemetry/endflow', {
        method: 'POST',
    })
        .then((response) => {
            if (response.ok) {
                response.text().then((_telemetryId) => {
                    setSpaRecord({ ...props.spaRecord, ...{ NLokTraceId: '' } });
                });
            }
        })
        .catch((response) => {
            telemetryLogger.info(
                `endFlow error, failed to remove NLokTraceId from session; errorCode=${response.status}, errorText=${response.statusText}`,
            );
        });
};

export { setTelemetryIdInSession, deleteTelemetryIdInSession };
