const SeverityLevel = {
    Verbose: 2000,
    Information: 3000,
    Warning: 4000,
    Error: 5000,
    Critical: 6000,
    Metrics: 7000,
};

export default SeverityLevel;
