const registerMNavClickHandler = () => {
    const headerElement = document.getElementById('ngp-main-header');
    const mNavElement = document.getElementById('ngp-main-header-m-nav');
    if (headerElement && mNavElement) {
        mNavElement.addEventListener('click', () => {
            mNavElement.classList.toggle('open');
            headerElement.classList.toggle('m-menu-active');
        });
    }
};

const header = () => {
    registerMNavClickHandler();
};

export default header;
