const parse = (jsonString) => {
    try {
        return JSON.parse(jsonString);
    } catch (error) {
        return {};
    }
};

const props = {
    layoutType: undefined,

    hasHeader: undefined,
    hasFooter: undefined,

    enableAnalytics: undefined,
    analyticsScriptUrl: undefined,
    analyticsAccount: undefined,
    analyticsSiteName: undefined,
    analyticsSectionName: undefined,
    analyticsPageName: undefined,

    enableSessionTimeout: undefined,
    sessionTimeoutInSeconds: undefined,
    sessionTimeoutPopupCountdownInSeconds: undefined,

    enableKeepAlive: undefined,
    keepAliveNotificationIntervalInSeconds: undefined,
    keepAliveUrls: undefined,

    enableEmbeddedMessageBox: undefined,

    enableMarketing: undefined,
    marketingAccountGuidHash: undefined,
    marketingEmailIdHash: undefined,

    enablePerformanceProfiling: undefined,

    displayLocale: undefined,
    selectorLocale: undefined,
    culture: undefined,
    twoCharacterLanguageCode: undefined,
    twoCharacterRegionCode: undefined,
    threeCharacterLanguageCode: undefined,
    threeCharacterRegionCode: undefined,

    spaDisplayMode: undefined,
    spaName: undefined,
    spaRecord: undefined,
    spaSessionData: undefined,
    spaMetadata: undefined,

    loggerType: undefined,
    clientLogLevel: undefined,

    resourceOverrides: undefined,
    spaCustomizations: undefined,
    cremeCustomization: undefined,
    maggiRecord: undefined,

    contextOverrides: undefined,
    spaTargetCriteriaOverrides: undefined,
    isAuthenticated: undefined,
    accountGuid: undefined,
    userCountry: undefined,

    nslBaseUrl: undefined,
    sessionState: undefined,
    tenantId: undefined,
    tenantName: undefined,
};

const populateProps = () => {
    let propsFromDom = JSON.parse(document.getElementById('global-props').dataset.props);

    const { overridePropsFromDom } = window;

    propsFromDom = { ...propsFromDom, ...overridePropsFromDom };

    props.layoutType = propsFromDom.layoutType;

    props.hasHeader = !!propsFromDom.hasHeader;
    props.hasFooter = !!propsFromDom.hasFooter;

    props.enableAnalytics = !!propsFromDom.enableAnalytics;
    props.analyticsScriptUrl = propsFromDom.analyticsScriptUrl;
    props.analyticsAccount = propsFromDom.analyticsAccount;
    props.analyticsSiteName = propsFromDom.analyticsSiteName;
    props.analyticsSectionName = propsFromDom.analyticsSectionName;
    props.analyticsPageName = propsFromDom.analyticsPageName;

    props.enableSessionTimeout = !!propsFromDom.enableSessionTimeout;
    props.sessionTimeoutInSeconds = propsFromDom.sessionTimeoutInSeconds;
    props.sessionTimeoutPopupCountdownInSeconds = propsFromDom.sessionTimeoutPopupCountdownInSeconds;

    props.enableKeepAlive = !!propsFromDom.enableKeepAlive;
    props.keepAliveNotificationIntervalInSeconds = propsFromDom.keepAliveNotificationIntervalInSeconds;
    props.keepAliveUrls = propsFromDom.keepAliveUrls;

    props.enableEmbeddedMessageBox = !!propsFromDom.enableEmbeddedMessageBox;

    props.enableMarketing = !!propsFromDom.enableMarketing;
    props.marketingAccountGuidHash = propsFromDom.marketingAccountGuidHash;
    props.marketingEmailIdHash = propsFromDom.marketingEmailIdHash;

    props.enablePerformanceProfiling = !!propsFromDom.enablePerformanceProfiling;

    props.displayLocale = propsFromDom.displayLocale;
    props.culture = propsFromDom.culture;
    props.selectorLocale = propsFromDom.selectorLocale;
    props.twoCharacterLanguageCode = propsFromDom.twoCharacterLanguageCode;
    props.twoCharacterRegionCode = propsFromDom.twoCharacterRegionCode;
    props.threeCharacterLanguageCode = propsFromDom.threeCharacterLanguageCode;
    props.threeCharacterRegionCode = propsFromDom.threeCharacterRegionCode;

    props.spaDisplayMode = propsFromDom.spaDisplayMode;
    props.spaName = propsFromDom.spaName;
    props.spaRecord = parse(propsFromDom.spaRecord);
    props.spaSessionData = propsFromDom.spaSessionData;
    props.spaMetadata = propsFromDom.spaMetadata;

    props.loggerType = propsFromDom.loggerType;
    props.clientLogLevel = propsFromDom.clientLogLevel;

    props.resourceOverrides = propsFromDom.resourceOverrides;
    props.cremeCustomization = parse(propsFromDom.cremeCustomization);
    props.spaCustomizations = propsFromDom.spaCustomizations;
    props.maggiRecord = parse(propsFromDom.maggiRecord);

    props.contextOverrides = propsFromDom.contextOverrides;
    props.spaTargetCriteriaOverrides = propsFromDom.spaTargetCriteriaOverrides;

    props.isAuthenticated = !!propsFromDom.isAuthenticated;
    props.accountGuid = propsFromDom.accountGuid;
    props.userCountry = propsFromDom.userCountry;

    props.nslBaseUrl = propsFromDom.nslBaseUrl;
    props.sessionState = propsFromDom.sessionState;
    props.tenantId = propsFromDom.tenantId;
    props.tenantName = propsFromDom.tenantName;
};

const isSpa = () => props.layoutType === 'spa';

const setSpaRecord = (spaRecord) => {
    props.spaRecord = spaRecord;
    window.viewBagRecord = JSON.stringify(props.spaRecord);
};

populateProps();

export default props;
export { isSpa, setSpaRecord };
