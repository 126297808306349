import props, { isSpa } from '../common/props';
import {
    logClickEvent,
    logCustomDownload,
    logCustomEvent,
    logCustomEventWithPropsAndVars,
    logPageView,
} from '../lib/analytics/analytics';

const analytics = () => {
    window.nortonAnalyticsVars = {
        account: props.analyticsAccount,
        site_language: props.twoCharacterLanguageCode,
        site_country: props.twoCharacterRegionCode,
        accountprofile_country: props.userCountry?.toLowerCase() || 'na',
        content_title: document.title,
        content_format: 'html',
        content_type: 'page',
        site_section: props.analyticsSiteName || '',
        site_sub_section: props.analyticsSectionName || '',
        page_name: props.analyticsPageName || '',
        signed_in: props.isAuthenticated ? 'signed in' : 'not signed in',
        psn: '',
        'presence of ar product': '',
        'olp sku': '',
        tenant_id: props.tenantId,
        days_to_expiration: '',
    };
    if (!isSpa() && (props.analyticsPageName || props.analyticsSectionName)) {
        window.nortonAnalytics = { ...window.nortonAnalytics, ...window.nortonAnalyticsVars };
    }
    window.OmnitureAnalyticsWrapper = {
        logPageView,
        logClickEvent,
        logCustomDownload,
        logCustomEvent,
        logCustomEventWithPropsAndVars,
    };
};

export default analytics;
