import { MaggiBumpModel } from '../models/maggi-models';
import LogHelper from './log-Helper';

class EventHelper {
    static publishMaggiEvent(repositoryName, currentVersion, sourceName, payload = null) {
        try {
            let bumpModel = new MaggiBumpModel();
            bumpModel.source = sourceName;
            bumpModel.updatedRepository = repositoryName;
            bumpModel.currentVersion = currentVersion;
            bumpModel.bumpPayload = payload;
            window.parent.postMessage({
                action: 'Maggi',
                payload: bumpModel,
            }, '*');
        } catch (e) {
            LogHelper.getMaggiLogger().error(`Error while publishing Maggi Event. Error=${e}`);
        }
    }
}

export default EventHelper;
