import props from '../../../common/props';

export function getViewBagRecord() {
    if (!props) {
        return undefined;
    }
    let viewBagRecord = props.spaRecord;
    if (viewBagRecord === undefined) {
        return undefined;
    }
    return viewBagRecord;
}

export function getSessionHash() {
    let viewBagRecord = getViewBagRecord();
    if (!viewBagRecord) {
        return undefined;
    }
    if (!viewBagRecord.SessionHash || viewBagRecord.SessionHash === '') {
        return undefined;
    }
    return viewBagRecord.SessionHash;
}
