import LoggerBase from './logger-base';
import SeverityLevel from './severity-level';

/**
 * Concrete implementation for ILogger that writes to the web console.
 * Meant for use in dev environments.
 */
class ConsoleLogger extends LoggerBase {
    execute(level, message, ...optionalParams) {
        const map = {
            [SeverityLevel.Verbose]: console.info,
            [SeverityLevel.Information]: console.info,
            [SeverityLevel.Warning]: console.warn,
            [SeverityLevel.Error]: console.error,
            [SeverityLevel.Critical]: console.error,
            [SeverityLevel.Metrics]: console.info,
        };

        const consoleMessage = [message].concat(optionalParams);
        map[level].apply(null, [consoleMessage]);
    }
}

export default ConsoleLogger;
