import props from '../../common/props';
import ConsoleLogger from './loggers/console-logger';
import ServerLogger from './loggers/server-logger';
import ServerLoggerHandler from './loggers/server-logger-handler';

const _consoleLogger = new ConsoleLogger();
const _serverLogger = new ServerLogger(new ServerLoggerHandler('ngpFrontendLogger'));

function createLogger(_name) {
    return props.loggerType !== 'Console' ? _serverLogger : _consoleLogger;
}

export default createLogger;
