class DataHelper {
    static tryGetJsonStringify(input) {
        try {
            if (input) return JSON.stringify(input);
        } catch (e) {
            return undefined;
        }
        return undefined;
    }

    static tryJsonParse(input) {
        try {
            if (input) return JSON.parse(input);
        } catch (e) {
            return undefined;
        }
        return undefined;
    }
}

export default DataHelper;
