import lifecycle from 'page-lifecycle';
import props from '../../../common/props';
import request from '../../utils/request';
import SeverityLevel from './severity-level';

class ServerLoggerHandler {
    loggerName = '';
    logArray = [];
    requestId = '';
    sendBeaconSupported = !!window.navigator.sendBeacon;
    batchSize = this.sendBeaconSupported ? 10 : 1;
    loggerApi = '/api/extspa/log/post';
    constructor(name) {
        this.loggerName = name;
        this.requestId = `SessionHash=${props.spaRecord?.SessionHash || ''};AccountGuid=${props.spaRecord?.NGPRequestId || ''};UserHash=${props.spaRecord?.UserHash || ''}`;

        if (props.spaName === 'preloadspa') return;

        if (this.sendBeaconSupported) {
            this.watchForBrowserStateChange();
        }
    }

    get loggerName() {
        return this.loggerName;
    }

    addToLogQueue(logLevel, message) {
        if (this.logArray.length < this.batchSize) {
            this.pushIntoLogQueue(logLevel, message);
        } else {
            this.sendLogsToServer();
            this.pushIntoLogQueue(logLevel, message);
        }
    }

    pushIntoLogQueue(logLevel, message) {
        this.logArray.push({
            l: logLevel,
            m: message,
            n: this.loggerName,
        });
    }

    watchForBrowserStateChange() {
        lifecycle.addEventListener('statechange', (event) => {
            if (event.newState === 'hidden') this.sendLogsToServer();
        });
    }

    sendLogsToServer() {
        if (this.logArray.length === 0) {
            return;
        }

        const headers = {
            type: 'application/json',
        };
        const body = { r: this.requestId, lg: this.logArray };
        if (this.sendBeaconSupported) {
            window.navigator.sendBeacon(this.loggerApi, new Blob([JSON.stringify(body)], headers));
        } else {
            request(this.loggerApi, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
        }
        this.logArray = [];
    }
}

export default ServerLoggerHandler;
