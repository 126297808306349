import KEY_MAPPINGS from '../utils/key-mappings';

const escapeKeyEvent = (event) => {
    if (event.key === KEY_MAPPINGS.Escape.key || event.keyCode === KEY_MAPPINGS.Escape.keyCode) {
        window.setOverlayVisibility(false);
    }
};

const escapeKeyEventListener = (event) => escapeKeyEvent(event);

const setOverlayVisibility = (showOverlay) => {
    window.top.postMessage({ action: 'setOverlayVisibility', payload: { showOverlay } }, window.location.origin);
};

const changeOverlayVisibility = (showOverlay) => {
    window.document.querySelector('.body-background-overlay').style.display = showOverlay ? 'block' : 'none';
};

const initializeOverlayVisibilityLib = () => {
    window.setOverlayVisibility = setOverlayVisibility;
    window.addEventListener('message', (event) => {
        if (event.data?.payload && event.data?.action === 'setOverlayVisibility' && event.origin === window.top.window.location.origin) {
            changeOverlayVisibility(event.data.payload.showOverlay);
            if (event.data.payload.showOverlay) {
                window.top.document.body.addEventListener('keydown', escapeKeyEventListener, true);
            } else {
                window.top.document.body.removeEventListener('keydown', escapeKeyEventListener, true);
            }
        }
    });
};

export default initializeOverlayVisibilityLib;
