import DataHelper from '../helpers/data-Helper';
import LogHelper from '../helpers/log-Helper';
import RepositoryHelper from '../helpers/repository-Helper';
import EventHelper from '../helpers/event-Helper';
import { DataSource, TenantHeader } from '../base/maggi-constants';
import BaseDataSourceListener from './base-datasource-listener';
import { SPOCPayload } from '../models/spoc-models';

class SPOCListener extends BaseDataSourceListener {
    static MAX_ERROR_RETRY_ATTEMPTS = 10;
    currentEntityInfo;
    _spocXhr;
    _spocUrl = '';
    _errorRetryCount = 0;
    _sessionRegistrationBatchCount = 0;
    maggiRepository;
    _repositoryHelper;
    constructor(_maggirepository) {
        super(_maggirepository);
        this.maggiRepository = _maggirepository;
        this._spocUrl = this.maggiRepository.maggiInfo.maggiConfiguration.spocUrl;
        this.currentEntityInfo = new SPOCPayload([]);
        this._repositoryHelper = new RepositoryHelper();
        let currentPayload = this.maggiRepository.getCurrentPayloadForDataSource(DataSource.SPOC);
        if (currentPayload && currentPayload.registration) {
            this.currentEntityInfo.registration = currentPayload.registration;
        } else {
            this.currentEntityInfo.registration = [];
        }
        setTimeout(() => { this.createSPOCRequest(true); }, 6000);
    }

    hasFirstRequestTriggered = false;

    listen(requestername, payload) {
        let didAppend = this.validateAndAppendToPayload(requestername, payload);
        if (didAppend && this.hasFirstRequestTriggered === true) this.createSPOCRequest();
    }

    deleteRegistration(requestername, payload) {
        let deleted = false;
        try {
            if (payload && payload.registration && payload.registration.length > 0) {
                payload.registration.forEach((regis) => {
                    if (regis && regis.entity !== undefined && regis.entity !== '' && regis.channel !== undefined) {
                        let entry = this.currentEntityInfo.registration.filter((reg) => reg.entity === regis.entity
                            && reg.channel === regis.channel);
                        if (entry && entry.length > 0) {
                            this.currentEntityInfo.registration = this.currentEntityInfo.registration.filter((reg) => !(reg.entity === regis.entity
                                && reg.channel === regis.channel));
                            deleted = true;
                        }
                    } else {
                        let errorString = `Maggi: DeleteRegistration Failed. InvalidPayload entity/channel for DataSource SPOC. Requester=${requestername} Payload=${DataHelper.tryGetJsonStringify(payload)}`;
                        LogHelper.getMaggiLogger().error(errorString);
                    }
                });
            } else {
                let errorString = `Maggi: DeleteRegistration Failed. InvalidPayload Format for DataSource SPOC. Requester=${requestername} Payload=${DataHelper.tryGetJsonStringify(payload)}`;
                LogHelper.getMaggiLogger().error(errorString);
            }
            if (deleted) {
                this.maggiRepository.setPayloadForDataSource(DataSource.SPOC, this.currentEntityInfo);
                LogHelper.getMaggiLogger().info(`Maggi: DeleteRegistration Completed. DeletedPayload=${DataHelper.tryGetJsonStringify(payload)}`);
                this.maggiRepository.syncServerRepo();
                this.createSPOCRequest();
            }
        } catch (e) {
            let errorString = `Maggi: DeleteRegistration Failed. requester= ${requestername} error=${e} Payload=${DataHelper.tryGetJsonStringify(payload)}`;
            LogHelper.getMaggiLogger().error(errorString);
        }
    }

    getBumpedPayloadInfo(lastKnownPayload) {
        let responsePayload = new SPOCPayload([]);
        try {
            if (lastKnownPayload && lastKnownPayload.registration && lastKnownPayload.registration.length > 0) {
                lastKnownPayload.registration.forEach((lastKnownRegis) => {
                    if (lastKnownRegis && lastKnownRegis.entity !== undefined && lastKnownRegis.entity !== '' && lastKnownRegis.channel !== undefined) {
                        let filteredRegistrations = this.currentEntityInfo.registration.filter((reg) => reg.entity === lastKnownRegis.entity && reg.channel === lastKnownRegis.channel && reg.revision > lastKnownRegis.revision);
                        filteredRegistrations.forEach((reg) => {
                            responsePayload.registration.push(reg);
                        });
                    }
                });
            } else {
                let errorString = `${'Maggi: getBumpedPayloadInfo Failed. InvalidPayload Format for DataSource SPOC. InputPayload='}${DataHelper.tryGetJsonStringify(lastKnownPayload)}`;
                LogHelper.getMaggiLogger().error(errorString);
                throw new Error(errorString);
            }
        } catch (e) {
            let errorString = `${'Maggi: getBumpedPayloadInfo Failed. error='}${e} InputPayload=${DataHelper.tryGetJsonStringify(lastKnownPayload)}`;
            LogHelper.getMaggiLogger().error(errorString);
        }
        return responsePayload;
    }

    validateAndAppendToPayload(requestername, payload) {
        let registered = false;
        try {
            if (payload && payload.registration && payload.registration.length > 0) {
                payload.registration.forEach((regis) => {
                    if (regis && regis.entity !== undefined && regis.entity !== '' && regis.channel !== undefined) {
                        if (this.currentEntityInfo.registration.filter((reg) => reg.entity === regis.entity
                            && reg.channel === regis.channel).length <= 0) {
                            this.currentEntityInfo.registration.push(regis);
                            registered = true;
                        }
                    } else {
                        let errorString = `Maggi: Registration Failed. InvalidPayload for DataSource SPOC. Payload=${DataHelper.tryGetJsonStringify(payload)}`;
                        LogHelper.getMaggiLogger().error(errorString);
                        throw new Error(errorString);
                    }
                });
                if (registered) {
                    this.maggiRepository.setPayloadForDataSource(DataSource.SPOC, this.currentEntityInfo);
                    LogHelper.getMaggiLogger().info(`Maggi: Registered with SPOC for Requester=${requestername} Payload=${DataHelper.tryGetJsonStringify(payload)}`);
                    this._sessionRegistrationBatchCount += 1;
                    if (this._sessionRegistrationBatchCount === 1) {
                        setTimeout(() => {
                            if (this._sessionRegistrationBatchCount === 1) this.maggiRepository.syncServerRepo();
                        }, 8000);
                    } else if (this._sessionRegistrationBatchCount > 1) {
                        this.maggiRepository.syncServerRepo();
                    }
                    return true;
                }
            }
        } catch (e) {
            let errorString = `Maggi: Registration Failed. error=${e} Payload=${DataHelper.tryGetJsonStringify(payload)}`;
            LogHelper.getMaggiLogger().error(errorString);
            throw new Error(errorString);
        }
        return false;
    }

    createSPOCRequest(bootUpRequest = false) {
        // Safety
        if (bootUpRequest && this.hasFirstRequestTriggered === true) return;
        if (this._spocXhr) {
            this._spocXhr.abort();
        }
        this._spocXhr = this.maggiRepository.createCORSXhrRequest('POST', this._spocUrl);
        if (this._spocXhr) {
            this._spocXhr.setRequestHeader('Content-Type', 'application/json');
            this._spocXhr.setRequestHeader('Accept', 'application/json');
            this._spocXhr.setRequestHeader(TenantHeader, this.maggiRepository.getTenantId());

            this._spocXhr.onreadystatechange = () => {
                if (this._spocXhr.readyState === 4 && this._spocXhr.status === 200) {
                    let responseJson = JSON.parse(this._spocXhr.responseText);

                    let responseRegis;
                    if (responseJson.registration) {
                        responseRegis = new SPOCPayload(responseJson.registration);
                    }
                    // TODO: Seggregate step 1, Step2, step3 to three methods. Currently this is having some scope challenges - hence keeping together
                    // 1. Update EntityStore
                    if (responseRegis && responseRegis.registration.length > 0) {
                        let responseRegistration = responseRegis.registration[0];
                        if (this.currentEntityInfo && this.currentEntityInfo.registration
                            && this.currentEntityInfo.registration.length > 0) {
                            // Update the currentEntityInfo Store
                            for (let i = 0; i < this.currentEntityInfo.registration.length; i += 1) {
                                if (responseRegistration.entity === this.currentEntityInfo.registration[i].entity
                                    && responseRegistration.channel === this.currentEntityInfo.registration[i].channel
                                ) {
                                    this.currentEntityInfo.registration[i].revision = responseRegistration.revision;
                                    break;
                                }
                            }

                            this.maggiRepository.setPayloadForDataSource(DataSource.SPOC, this.currentEntityInfo);
                        }

                        // 2. Publish Event to SPAs
                        let updatedRepositories = this._repositoryHelper.RepositoryToSPOCChannelMap.filter((re) => (re.Channels.indexOf(responseRegistration.channel) > -1));
                        if (updatedRepositories && updatedRepositories.length > 0) {
                            updatedRepositories.forEach((re) => {
                                window.maggi.bump(re.Repository);
                                EventHelper.publishMaggiEvent(re.Repository, window.maggi.getRepositoryVersion()[re.Repository],
                                    'spoc', responseRegistration);
                            });
                        }
                        this.maggiRepository.syncServerRepo();
                    }
                    this._errorRetryCount = 0;
                    // 3. Create Next Request
                    this.createSPOCRequest();
                } else if (this._spocXhr.readyState === 4 && (this._spocXhr.status === 0 || this._spocXhr.status > 200)) {
                    if (this._errorRetryCount <= SPOCListener.MAX_ERROR_RETRY_ATTEMPTS) {
                        this._errorRetryCount += 1;
                        this.createSPOCRequest();
                    } else {
                        let errorString = `Maggi: SPOC connection Failed. ErrorCode=${this.status} statusText=${this.statusText
                        } response=${this._spocXhr.response ? DataHelper.tryGetJsonStringify(this._spocXhr.response) : ''
                        } responsetext=${this._spocXhr.responseText
                        } responseHeaders=${this._spocXhr.getAllResponseHeaders()
                        } userAgent=${window.navigator.userAgent}`;
                        LogHelper.getMaggiLogger().error(errorString);
                    }
                }
            };
            if (this.currentEntityInfo && this.currentEntityInfo.registration && this.currentEntityInfo.registration.length > 0) {
                this._spocXhr.send(JSON.stringify(this.currentEntityInfo));
            }
            this.hasFirstRequestTriggered = true;
        }
    }
}

export default SPOCListener;
