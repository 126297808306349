const KEY_MAPPINGS = {
    Escape: {
        key: 'Escape',
        keyCode: 27,
    },
    Tab: {
        key: 'Tab',
        keyCode: 9,
    },
};

export default KEY_MAPPINGS;
