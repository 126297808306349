const PlatformVariableNames = {
    PLATFORM_TENANT_NAME: 'platform.tenantname',
    PLATFORM_TENANT_ID: 'platform.tenantid',
    PLATFORM_PARTNER_CODE: 'platform.pcode',
};
const FlavorizerConstants = {
    OPTIONS_DELIMITER: '|',
    OPTIONS_INCLUSION_DELIMITER: '~',
    OPTIONS_EXCLUSION_DELIMITER: '!',
    OPTIONS_ENCLOSER_OPEN: '(',
    OPTIONS_ENCLOSER_CLOSE: ')',
    VARIABLE_ENCLOSER_OPEN: '[',
    VARIABLE_ENCLOSER_CLOSE: ']',
    TEMPLATE_ENCLOSER_OPEN: '{{',
    TEMPLATE_ENCLOSER_CLOSE: '}}',
    SEGMENT_DELIMITER: ';',
    EMPTY: '',
};
const IsSupportedOverride = (variableName, valFromContext, spaTargetCriteriaOverrides) => {
    if (variableName === PlatformVariableNames.PLATFORM_PARTNER_CODE && spaTargetCriteriaOverrides?.PartnerCodeList?.length > 0) {
        return spaTargetCriteriaOverrides.PartnerCodeList.includes(valFromContext);
    }
    return true;
};

const removeBlockMarkers = (data) => data?.replace(FlavorizerConstants.TEMPLATE_ENCLOSER_OPEN, FlavorizerConstants.EMPTY)?.replace(FlavorizerConstants.TEMPLATE_ENCLOSER_CLOSE, FlavorizerConstants.EMPTY);

const validateVariableName = (variable = '', currentContext, spaTargetCriteriaOverrides) => {
    const variableName = variable.slice(0, -1);
    if (variableName && variable.endsWith(FlavorizerConstants.SEGMENT_DELIMITER) && Object.prototype.hasOwnProperty.call(currentContext, variableName)) {
        const valFromContext = currentContext[variableName];
        if (variableName === PlatformVariableNames.PLATFORM_TENANT_NAME || variableName === PlatformVariableNames.PLATFORM_TENANT_ID) {
            // No need to check the supportedOverrides if the variable is tenant name/id
            return {
                isValidFormat: true,
                variableValue: valFromContext,
                isValidReplacement: true,
            };
        }
        const isValidReplacement = IsSupportedOverride(variableName, valFromContext, spaTargetCriteriaOverrides);
        return {
            isValidFormat: true,
            variableValue: isValidReplacement ? valFromContext : FlavorizerConstants.EMPTY,
            isValidReplacement,
        };
    }
    return {
        isValidFormat: false,
        isValidReplacement: false,
    };
};

const FlavorizerEngine = (inputContent, currentContext, spaTargetCriteriaOverrides) => {
    let variableName = '';
    let validationErrorStartIndex;
    for (; ;) {
        const blockStart = inputContent.indexOf(FlavorizerConstants.TEMPLATE_ENCLOSER_OPEN);
        if (blockStart < 0) break; // No template found so just return the string as it is

        const blockEnd = inputContent.indexOf(FlavorizerConstants.TEMPLATE_ENCLOSER_CLOSE);
        const blockData = inputContent.substring(blockStart + FlavorizerConstants.TEMPLATE_ENCLOSER_OPEN.length, blockEnd);

        if (blockEnd < 0) {
            // {{ not terminated properly
            validationErrorStartIndex = blockStart;
            break;
        }
        if (!blockData) {
            inputContent = removeBlockMarkers(inputContent);
            // eslint-disable-next-line
            continue;
        }
        const variableStart = blockData.indexOf(FlavorizerConstants.VARIABLE_ENCLOSER_OPEN);
        const variableEnd = blockData.indexOf(FlavorizerConstants.VARIABLE_ENCLOSER_CLOSE);
        if (variableStart < 0) {
            // No variable found so move on to the next block
            inputContent = removeBlockMarkers(inputContent);
            // eslint-disable-next-line
            continue;
        }

        if (variableStart > 0 && variableEnd < 0) {
            // [ not terminated properly
            validationErrorStartIndex = blockStart;
            break;
        }
        if (variableStart !== blockData.lastIndexOf(FlavorizerConstants.VARIABLE_ENCLOSER_OPEN)) {
            // Multiple variables present
            validationErrorStartIndex = blockStart;
            break;
        }

        variableName = blockData.substring(variableStart + FlavorizerConstants.VARIABLE_ENCLOSER_OPEN.length, variableEnd);
        const { isValidFormat, variableValue, isValidReplacement } = validateVariableName(variableName, currentContext, spaTargetCriteriaOverrides);
        if (!isValidFormat) {
            validationErrorStartIndex = blockStart;
            break;
        }
        if (isValidReplacement) {
            inputContent = inputContent.replace(`${FlavorizerConstants.VARIABLE_ENCLOSER_OPEN}${variableName}${FlavorizerConstants.VARIABLE_ENCLOSER_CLOSE}`, variableValue);
            inputContent = removeBlockMarkers(inputContent);
        } else {
            inputContent = `${inputContent.substring(0, blockStart)}${inputContent.substring(blockEnd + FlavorizerConstants.TEMPLATE_ENCLOSER_CLOSE.length)}`; // Remove the entire block in case of invalid replacement
        }
    }

    return validationErrorStartIndex ? `Error at char ${validationErrorStartIndex}.` : inputContent;
};

export default FlavorizerEngine;
