import KEY_MAPPINGS from '../utils/key-mappings';

let containerElement;
let activeElement;

const isFocusable = (elem) => {
    if (elem.tabIndex > 0 || (elem.tabIndex === 0 && elem.getAttribute('tabIndex') !== null) || elem.getAttribute('contenteditable') !== null) {
        return true;
    }

    if (elem.disabled || (!elem.getBoundingClientRect().width && !elem.getBoundingClientRect().height)) {
        return false;
    }

    switch (elem.nodeName) {
        case 'A':
            return !!elem.href && elem.rel !== 'ignore';
        case 'INPUT':
            return elem.type !== 'hidden';
        case 'BUTTON':
        case 'SELECT':
        case 'TEXTAREA':
        case 'AREA':
        case 'OBJECT':
        case 'EMBED':
        case 'IFRAME':
            return true;
        default:
            return false;
    }
};

const focusFirstElement = () => {
    const focusableElements = Array.from(containerElement.querySelectorAll('*')).filter(isFocusable);
    focusableElements[0].focus();
    window.parent.scrollTo(0, 0);
};

const checkAndRefocusInsideContainer = (event) => {
    if (!containerElement.contains(event.target)) {
        activeElement.focus();
    }
};

const checkAndRefocusInsideContainerEventListener = (event) => checkAndRefocusInsideContainer(event);

const beginTrapFocus = (event, untrapFocusHandler) => {
    if (event.key === KEY_MAPPINGS.Tab.key || event.keyCode === KEY_MAPPINGS.Tab.keyCode) {
        const focusableElements = Array.from(containerElement.querySelectorAll('*')).filter(isFocusable);
        let nextElementToFocus;
        if (isFocusable(event.target)) {
            nextElementToFocus = event.target;
        } else {
            const currentFocusedElementIndex = focusableElements.findIndex((elem) => elem === document.activeElement);
            if (currentFocusedElementIndex === focusableElements.length + 1) {
                [nextElementToFocus] = focusableElements;
            } else {
                nextElementToFocus = focusableElements[currentFocusedElementIndex + 1];
            }
        }

        if (event.shiftKey) {
            // Shift + Tab
            if (focusableElements[0] === nextElementToFocus) {
                focusableElements[focusableElements.length - 1].focus();
                activeElement = document.activeElement;
                event.preventDefault();
            }
        } else if (focusableElements[focusableElements.length - 1] === nextElementToFocus) {
            focusableElements[0].focus();
            activeElement = document.activeElement;
            event.preventDefault();
        }
    } else if (event.key === KEY_MAPPINGS.Escape.key || event.keyCode === KEY_MAPPINGS.Escape.keyCode) {
        event.stopPropagation();
        untrapFocusHandler();
    }
};

const untrapFocus = () => {
    /* eslint-disable */
    if (!containerElement)
        return;
    containerElement.removeEventListener('keydown', trapFocusEventListener);
    containerElement.removeEventListener('focus', setActiveElementEventListener, true);
    window.top.document.body.removeEventListener('click', checkAndRefocusInsideContainerEventListener, true);
    containerElement = null;
    activeElement = null;
    /* eslint-enable */
};

const setActiveElement = () => {
    activeElement = document.activeElement;
};

const trapFocusEventListener = (event) => beginTrapFocus(event, untrapFocus);
const setActiveElementEventListener = () => setActiveElement();

const trapFocus = (elementId) => {
    if (containerElement) {
        untrapFocus();
    }
    containerElement = document.getElementById(elementId);
    if (!containerElement) {
        throw Error(`An element with the id ${elementId} could not be found`);
    }

    containerElement.addEventListener('keydown', trapFocusEventListener);
    containerElement.addEventListener('focus', setActiveElementEventListener, true);
    focusFirstElement();
    // To prevent any of the outer elements from gaining focus
    window.top.document.body.addEventListener('click', checkAndRefocusInsideContainerEventListener, true);
};

const initializeTrapFocusLib = () => {
    window.trapFocus = trapFocus;
    window.untrapFocus = untrapFocus;
};

export default initializeTrapFocusLib;
