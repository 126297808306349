class BaseDataSourceListener {
    maggiRepository;
    constructor(_maggirepository) {
        this.maggiRepository = _maggirepository;
    }

    listen(requestername, payload) {
        throw new Error('BaseDataSourceListener > listen is base class.Use Derieved class function');
    }

    deleteRegistration(requestername, payload) {
        throw new Error('BaseDataSourceListener > deleteRegistration  is base class.Use Derieved class function');
    }

    getBumpedPayloadInfo(lastKnownPayload) {
        throw new Error('BaseDataSourceListener > getBumpedPayloadInfo is base class.Use Derieved class function');
    }
}

export default BaseDataSourceListener;
