import LoggerBase from './logger-base';

/**
 * Concrete implementation for ILogger that writes to Server side log file.
 * Meant for use in non-dev environments.
 */
class ServerLogger extends LoggerBase {
    loggerName = '';
    serverLoggerHandler;

    constructor(serverLoggerHandler) {
        super();

        this.loggerName = serverLoggerHandler.loggerName;
        this.serverLoggerHandler = serverLoggerHandler;
    }

    execute(level, message, ...optionalParams) {
        const payload = JSON.stringify([message].concat(optionalParams));
        this.serverLoggerHandler.addToLogQueue(level, payload?.slice(0, 15000));
    }
}

export default ServerLogger;
