import props from '../../common/props';
import request from '../utils/request';

const spaName = props.spaName ?? '';

const getSessionData = () => {
    if (props.spaSessionData?.SessionData) return decodeURI(props.spaSessionData.SessionData);
    return undefined;
};

const postSessionData = (content, successCallback, errorCallback) => {
    request('/api/spa/SetSpaData', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(content),
    })
        .then((response) => {
            if (response.ok) {
                response.text().then((text) => successCallback(text));
            } else {
                errorCallback(response.statusText);
            }
        })
        .catch((response) => {
            errorCallback(response.statusText);
        });
};

const setSessionData = (content) => {
    return new Promise((resolve, reject) => {
        if (typeof content !== 'string') {
            reject(new Error('Expected "content" to be of type string'));
            return;
        }

        const spaSessionDataRequest = { SpaName: spaName, SessionData: encodeURI(content) };
        postSessionData(
            spaSessionDataRequest,
            (_value) => {
                props.spaSessionData = { ...props.spaSessionData, ...{ SessionData: content } };
                resolve();
            },
            (err) => {
                reject(err);
            },
        );
    });
};

export { getSessionData, setSessionData };
