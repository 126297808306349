import props from '../../common/props';
import createLogger from '../logging/ngp-logger';

let start;
let spaName;
let browser;
const logger = createLogger('ngmpPerfLogger');

const mutationObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
        const delta = Date.now() - start;
        const domElement = mutation.target.localName;
        if (domElement === 'div') {
            let logElement = domElement;

            const domElementId = mutation.target.id;
            if (domElementId) logElement += `#${domElementId}`;

            const domElementClasses = mutation.target.classList.value;
            if (domElementClasses) logElement += `.${domElementClasses}`;
        }
    });
});

const startDomObserver = () => {
    mutationObserver.observe(document.documentElement, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true,
    });
};

const startTimingSession = () => {
    start = Date.now();
    spaName = props.spaName;

    console.log(`Starting spa ${spaName}`);
    logger.debug(`spa_debug,start,C=${spaName},T=${start}`);
};

const recordSpaLoadComplete = (eventData) => {
    const loadTime = (Date.now() - start) / 1000;

    let shouldLog = true;
    if (eventData.spa !== spaName) {
        shouldLog = false;
    }

    let logSpaName = eventData.spa;
    const recordedSpa = window.sessionStorage.getItem('spaLoads');
    if (recordedSpa) {
        if (recordedSpa.split(',').includes(logSpaName)) {
            logSpaName += ':repeat';
        } else if (shouldLog) {
            window.sessionStorage.setItem('spaLoads', `${recordedSpa},${logSpaName}`);
        }
    } else if (shouldLog) {
        window.sessionStorage.setItem('spaLoads', logSpaName);
    }

    logger.debug(`spa_debug,end,C=${spaName},A=${eventData.spa},T=${Date.now()}`);

    let extras = {};
    if (!eventData.extras) {
        extras = eventData.extras;
    }
    if (shouldLog) {
        let logLine = `SPA_LOAD_COMPLETE,SPA=${logSpaName},T=${loadTime}`;
        // TODO extras.ngp_browser = browser;
        logLine += `,E=${JSON.stringify(extras)}`;
        logger.metrics(logLine);
        spaName = undefined;
    }
    console.log(`Completed loading ${logSpaName} spa in ${loadTime} seconds; extras=${JSON.stringify(extras)}`);
};

export { startDomObserver, startTimingSession, recordSpaLoadComplete };
