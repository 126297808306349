import { Repositories } from '../base/maggi-constants';

class MaggiRepoInfoModel {
    currentRepositoryVersion = {};
    maggiPayloadInfo = [];
    constructor() {
        this.currentRepositoryVersion = {};
        let keysOfRepo = Object.keys(Repositories);
        keysOfRepo.forEach((key) => {
            this.currentRepositoryVersion[key] = 0;
        });
        this.maggiPayloadInfo = [];
    }
}

class MaggiConfigurationModel {
    spocUrl = '';
}

class MaggiInfoModel {
    maggiRepoInfo = new MaggiRepoInfoModel();
    maggiConfiguration = new MaggiConfigurationModel()
}

class MaggiPayloadInfo {
    datasourceType = '';
    currentPayloadInfo;

    constructor(_datasourceType, _currentPayloadInfo) {
        this.datasourceType = _datasourceType;
        this.currentPayloadInfo = _currentPayloadInfo;
    }
}

class MaggiBumpModel {
    updatedRepository = '';
    source = '';
    currentVersion = -1;
    bumpPayload = {};
}

export {
    MaggiBumpModel, MaggiPayloadInfo, MaggiInfoModel, MaggiConfigurationModel, MaggiRepoInfoModel,
};
