class RepositoryMap {
    Repository = '';
    Channels = [];

    constructor(repo, channels) {
        this.Repository = repo;
        this.Channels = channels;
    }
}

export default RepositoryMap;
