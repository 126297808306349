import platformProps from '../../common/props';

const assignSiteName = () => {
    window.s.linkTrackVars = `${window.s.linkTrackVars},prop41`;
    window.s.prop41 = platformProps.analyticsSiteName;

    window.s.linkTrackVars = `${window.s.linkTrackVars},eVar41`;
    window.s.eVar41 = platformProps.analyticsSiteName;
};

const assignVariables = (props, eVars) => {
    if (props) {
        props.forEach((prop) => {
            window.s.linkTrackVars = `${window.s.linkTrackVars},${prop.propName}`;
            window.s[prop.propName] = prop.propVal;
        });
    }
    if (eVars) {
        eVars.forEach((eVar) => {
            window.s.linkTrackVars = `${window.s.linkTrackVars},${eVar.eVarName}`;
            window.s[eVar.eVarName] = eVar.eVarVal;
        });

        let analyticsData = window.nortonAnalytics;
        if (eVars && eVars.length > 0) {
            const evar54Object = eVars.find((x) => x.eVarName === 'eVar54' && x.eVarVal !== undefined);
            if (evar54Object) {
                analyticsData['olp sku'] = evar54Object.eVarVal;
            }
            const evar6Object = eVars.find((x) => x.eVarName === 'eVar6' && x.eVarVal !== undefined);
            if (evar6Object) {
                analyticsData.days_to_expiration = evar6Object.eVarVal;
            }
        }
    }
    if (window.user?.naGuid) {
        window.s.linkTrackVars = `${window.s.linkTrackVars},eVar32`;
        window.s.eVar32 = window.user.naGuid;
    }
};

const assignTenantId = () => {
    if (platformProps.tenantId) {
        window.s.linkTrackVars = `${window.s.linkTrackVars},eVar10`;
        window.s.eVar10 = platformProps.tenantId;
    }
};

const clearVars = () => {
    window.s.clearVars('events');
};

const logPageView = (pageName, props, eVars) => {
    if (window.s) {
        let analyticsData = window.nortonAnalytics;
        analyticsData.page_name = pageName;
        if (window.user?.naGuid) analyticsData.norton_guid = window.user.naGuid;
        if (window.trackPageView) {
            // This clear call ensures that all vars, props and events are cleared prior to page tracking.
            clearVars();
            assignVariables(props, eVars);
            assignTenantId();
            window.trackPageView(analyticsData);
        }
    }
};

const logClickEvent = (eventName, props, eVars) => {
    if (window.s) {
        clearVars();
        assignSiteName();
        assignVariables(props, eVars);
        window.s.tl(this, 'o', eventName);
    }
};

const logCustomDownload = (url, fileName, fileType) => {
    if (window.s) {
        clearVars();
        window.trackCustomDownload(url, fileName, fileType);
    }
};

const logCustomEvent = (eventName, eventNumber) => {
    if (window.s) {
        clearVars();
        window.s.linkTrackVars = 'events';
        window.s.linkTrackEvents = eventNumber;
        window.s.events = eventNumber;
        assignSiteName();
        window.s.tl(this, 'o', eventName);
    }
};

const logCustomEventWithPropsAndVars = (eventName, eventNumber, props, eVars) => {
    if (window.s) {
        clearVars();
        window.s.linkTrackVars = 'events';
        window.s.linkTrackEvents = eventNumber;
        window.s.events = eventNumber;
        assignSiteName();
        assignVariables(props, eVars);
        window.s.tl(this, 'o', eventName);
    }
};

export {
    logPageView,
    logClickEvent,
    logCustomDownload,
    logCustomEvent,
    logCustomEventWithPropsAndVars,
};
