import props from '../../../common/props';
import SeverityLevel from './severity-level';

class LoggerBase {
    constructor() {
        if (new.target === LoggerBase) {
            throw TypeError(`Cannot instantiate abstract class ${this.constructor.name}`);
        }
    }

    debug(message, ...optionalParams) {
        this.levelAwareExecute(SeverityLevel.Verbose, message, optionalParams);
    }

    info(message, ...optionalParams) {
        this.levelAwareExecute(SeverityLevel.Information, message, optionalParams);
    }

    warn(message, ...optionalParams) {
        this.levelAwareExecute(SeverityLevel.Warning, message, optionalParams);
    }

    error(message, ...optionalParams) {
        this.levelAwareExecute(SeverityLevel.Error, message, optionalParams);
    }

    critical(message, ...optionalParams) {
        this.levelAwareExecute(SeverityLevel.Critical, message, optionalParams);
    }

    metrics(message, ...optionalParams) {
        this.levelAwareExecute(SeverityLevel.Metrics, message, optionalParams);
    }

    levelAwareExecute(level, message, ...optionalParams) {
        if (level >= props.clientLogLevel) this.execute(level, message, optionalParams);
    }

    execute(_level, _message, ..._optionalParams) {
        // To be implemented by child classes (i.e. The actual loggers)
        throw new Error('Method "execute" not implemented');
    }
}

export default LoggerBase;
