import { MaggiRepoInfoModel } from './models/maggi-models';
import MaggiRepository from './base/maggi-repository';
import MaggiListner from './listeners/maggi-listener';
import EventHelper from './helpers/event-Helper';

class Maggi {
    _maggiRepository;
    listener;

    constructor() {
        this._maggiRepository = new MaggiRepository();
        this.listener = new MaggiListner(this._maggiRepository);
    }

    update(repositoryName, sourceName, payload = null) {
        this.bump(repositoryName);
        let repoInfo = this._maggiRepository.getRepoInfo();
        if (!repoInfo) repoInfo = new MaggiRepoInfoModel();
        EventHelper.publishMaggiEvent(repositoryName, repoInfo.currentRepositoryVersion[repositoryName],
            sourceName, payload);
        this._maggiRepository.syncServerRepo();
        this.markDirty(repositoryName);
    }

    bump(repositoryName) {
        let repoInfo = this._maggiRepository.getRepoInfo();
        if (!repoInfo) repoInfo = new MaggiRepoInfoModel();
        if (repoInfo.currentRepositoryVersion[repositoryName] === undefined) {
            repoInfo.currentRepositoryVersion[repositoryName] = 1;
        } else {
            repoInfo.currentRepositoryVersion[repositoryName] += 1;
        }
        this._maggiRepository.setRepoInfo(repoInfo);
    }

    getRepositoryVersion() {
        let repoInfo = this._maggiRepository.getRepoInfo();
        if (!repoInfo) repoInfo = new MaggiRepoInfoModel();
        return repoInfo.currentRepositoryVersion;
    }

    markDirty(repositoryName) {
        const _eligibleRepositories = new Set(['subscription', 'devices', 'offer']);
        // Notify platform to clear cached data for these repositories only.
        if (_eligibleRepositories.has(repositoryName)) {
            this._maggiRepository.markDirty(repositoryName);
        }
    }
}

export default Maggi;
