import '../styles/app.scss';
// Props should be imported first here to ensure that it's populated before the browser executes anything else.
import props, { isSpa } from './common/props';
import analytics from './pages/analytics';
import idsafeStatus from './pages/browser-extension-status';
import common from './pages/common';
import header from './pages/header';
import spa from './pages/spa';
import './polyfills';

common();
if (isSpa()) spa();

if (props.hasHeader) header();

if (props.spaName === 'dashboard' || props.spaName === 'dashboarddev') {
    idsafeStatus();
}

if (props.enableAnalytics) analytics();
if (props.isAuthenticated) {
    import('./pages/session-management').then(({ default: sessionManagement }) => sessionManagement());
}
if (props.enableEmbeddedMessageBox) {
    import('./pages/embedded-message-box').then(({ default: embeddedMessageBox }) => embeddedMessageBox());
}
if (props.enableMarketing) {
    setTimeout(() => {
        import('./pages/marketing').then(({ default: marketing }) => marketing());
    }, 10 * 1000);
}

if (props.enablePerformanceProfiling) {
    import('./pages/perf').then(({ default: perf }) => perf());
}
