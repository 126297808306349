export const DataSource = {
    SPOC: 'SPOC', // Currently supported
};

export const Repositories = {
    subscription: 'subscription',
    offer: 'offer',
    devices: 'devices',
    messagebox: 'messagebox',
    nof: 'nof',
    dashboard: 'dashboard',
};

export const SPOCChannel = {
    SC_DATASTORE_UPDATE: 2,
    SC_REGISTRATION_UPDATE: 3,
    SC_EVENT_LOG_UPDATE: 7,
    SC_REGISTRATION_DELETE: 9,
    SC_EVENT_LOG_LOCATION: 10,
    SC_EVENT_LOG_PRIORITY_70: 19,
    SC_NF_DATA_REQUEST_ACK: 21,
    SC_NF_OS_ACCOUNT_ADD: 24,
    SC_DSP_DASHBOARD_UPDATE: 55,
    SC_MTS_MESSAGE: 100,
};

export const StorageMode = {
    SESSION: 'SESSION',
    INMEMORY: 'INMEMORY',
};

export const TenantHeader = 'X-NLOK-Tenant-ID';
