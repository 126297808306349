import { DataSource } from '../base/maggi-constants';
import MaggiRepository from '../base/maggi-repository';
import SPOCListener from './spoc-listener';

export default class ListnerFactory {
    _listeners = [];
    maggirepository;
    constructor(_maggirepository) {
        if (_maggirepository instanceof MaggiRepository) this.maggirepository = _maggirepository;
    }

    getListner(dataSource) {
        let returnListner = {};
        let spocListeners = [];
        switch ((dataSource || '').toLocaleString().toLocaleLowerCase()) {
            case DataSource[DataSource.SPOC].toLocaleString().toLocaleLowerCase():
                spocListeners = this._listeners.filter((lis) => lis instanceof SPOCListener);
                if (spocListeners && spocListeners.length > 0) {
                    let firstindex = 0;
                    returnListner = spocListeners[firstindex];
                } else {
                    returnListner = new SPOCListener(this.maggirepository);
                    this._listeners.push(returnListner);
                }
                return returnListner;

            default: return undefined;
        }
    }
}
