import props from '../common/props';
import { logClickEvent } from '../lib/analytics/analytics';
import initializeOverlayVisibilityLib from '../lib/background-overlay/background-overlay';
import flavorize from '../lib/flavorizer/flavorizer';
import { updateLocaleProps, updateLocaleVars } from '../lib/locale/locale';
import initializeTrapFocusLib from '../lib/trap-focus/trap-focus';
import getXsrfToken from '../lib/utils/xsrf';

const enableTooltipDismissalOnIos = () => {
    if ('ontouchstart' in document.documentElement) {
        document.querySelector('body').style.cursor = 'pointer';
    }
};

const initializeLocaleDetails = () => {
    window.updateLocaleProps = updateLocaleProps;
    window.onngplanguagechange = null;
    updateLocaleVars();
};

const initializeUserDetails = () => {
    window.user = {
        naGuid: props.accountGuid,
    };
    window.Platform.User = {
        isAuthenticated: props.isAuthenticated,
        accountGuid: props.accountGuid,
    };
};

const initializeBrowserDetails = () => {
    window.browserInfo = {
        lang_two_letter: props.twoCharacterLanguageCode,
        locale_two_letter: props.twoCharacterRegionCode,
        lang_three_letter: props.threeCharacterLanguageCode,
        locale_three_letter: props.threeCharacterRegionCode,
    };
};

const appendLocaleToDocumentBody = () => {
    document.body.classList.add(`${props.threeCharacterLanguageCode}-${props.threeCharacterRegionCode.toUpperCase()}`);
};

const handleButtonClick = (url, eventName) => {
    try {
        if (eventName && props.enableAnalytics) {
            logClickEvent(eventName);
        }
    } catch (error) {
        console.log(error);
    }
    window.location.href = url;
};

const initializeFlavorizer = () => {
    window.Platform.Flavorizer = {
        flavorize,
    };
};

const common = () => {
    window.Platform = {};
    initializeLocaleDetails();
    initializeBrowserDetails();
    initializeUserDetails();
    appendLocaleToDocumentBody();
    initializeTrapFocusLib();
    initializeOverlayVisibilityLib();
    initializeFlavorizer();

    window.handleButtonClick = handleButtonClick;
    window.RequestVerificationToken = getXsrfToken();

    enableTooltipDismissalOnIos();
};

export default common;
