import props, { setSpaRecord } from '../common/props';
import {
    getAccessToken,
    handleAccessTokenValidationFailure,
    makeTokenCall,
    notifySpaContainer,
    refreshAccessToken,
    revalidateSession,
    spaLogout,
} from '../lib/auth/platform-auth';
import { getSessionData, setSessionData } from '../lib/auth/session-data';
import Maggi from '../lib/maggi/maggi';
import { deleteTelemetryIdInSession, setTelemetryIdInSession } from '../lib/telemetry/telemetry';
import { recordSpaLoadComplete, startTimingSession } from '../lib/timing/timing-utils';

const initializeAuth = () => {
    setSpaRecord(props.spaRecord);
    window.makeTokenCall = makeTokenCall;
    window.RevalidateSession = revalidateSession;
    window.spaLogout = spaLogout;
    window.getATAsync = getAccessToken;

    window.handleATValidationFailure = handleAccessTokenValidationFailure;
    window.SpaAuth = {
        getATAsync: getAccessToken,
        handleATValidationFailure: handleAccessTokenValidationFailure,
        refreshAccessToken,
    };
    window.notifySPAContainer = notifySpaContainer;
    window.platformstorage = {
        getData: getSessionData,
        setData: setSessionData,
    };
};

const getSpaCustomizations = () => {
    let spaName = props.spaName.replace(/dev$/, '');
    return {
        [spaName]: props.spaCustomizations,
    };
};

const getResourceOverrides = () => props.resourceOverrides;

const initializeTiming = () => {
    const onRecordSpaLoadEvent = (event) => {
        if (event.data.type === 'RECORDSPALOAD') {
            recordSpaLoadComplete(event.data);
        }
    };

    window.addEventListener('message', onRecordSpaLoadEvent, false);
    startTimingSession();
};

const initializeTelemetry = () => {
    window.Telemetry = {
        startFlow: setTelemetryIdInSession,
        endFlow: deleteTelemetryIdInSession,
    };
};

const initializeAndRegisterMaggi = () => {
    if (props.spaRecord && props.accountGuid && props.accountGuid !== '') {
        window.maggi = new Maggi();
        window.top.maggi.listener.register('Platform', 'SPOC', {
            registration: [{ entity: props.accountGuid, channel: 3, revision: 0 }],
        });
        window.top.maggi.listener.register('messagebox', 'SPOC', {
            registration: [{ entity: props.accountGuid, channel: 100, revision: 0 }],
        });
    }
};

const initializeAutoLogout = () => {
    if (window.top.autoLogoutInitialized) return;
    if (props.isAuthenticated) {
        window.autoLogoutInitialized = true;
        import('../lib/auth/auto-logout').then(({ default: startCheckingSessionState }) => startCheckingSessionState());
    }
};

const spa = () => {
    initializeAuth();
    window.Platform.Spa = {
        id: props.spaName,
        metadata: props.spaMetadata,
    };
    window.getSpaCustomizations = getSpaCustomizations;
    window.getResourceOverrides = getResourceOverrides;

    initializeAndRegisterMaggi();
    initializeTelemetry();
    initializeTiming();

    if (props.spaMetadata?.DisableSessionStateSync !== 'true') {
        initializeAutoLogout();
    }
};

export default spa;
