import ListnerFactory from './listener-factory';
import { DataSource } from '../base/maggi-constants';
import MaggiRepository from '../base/maggi-repository';

class MaggiListner {
    _listnerFactory;
    maggiRepository;
    constructor(_maggirepository) {
        if (_maggirepository instanceof MaggiRepository) {
            this.maggiRepository = _maggirepository;
            this._listnerFactory = new ListnerFactory(this.maggiRepository);
        }
    }

    register(requestername, dataSource, payload) {
        let listner = this._listnerFactory.getListner(dataSource);
        if (listner) listner.listen(requestername, payload);
        else {
            throw new Error(`Invalid dataSourceType for Register. Received DataSourceType=${dataSource}`);
        }
    }

    deleteRegistration(requestername, dataSource, payload) {
        let listner = this._listnerFactory.getListner(dataSource);
        if (listner) listner.deleteRegistration(requestername, payload);
        else {
            throw new Error(`Invalid dataSourceType for deleteRegistration. Received DataSourceType=${dataSource}`);
        }
    }

    getCurrentPayloadInfo(dataSource) {
        let dataSources = Object.keys(DataSource).map((ds) => (DataSource)[ds]);
        if (dataSources.indexOf(dataSource.toString()) > -1) return this.maggiRepository.getCurrentPayloadForDataSource(dataSource);
        throw new Error(`Invalid dataSourceType for getCurrentPayloadInfo. Received DataSourceType=${dataSource}`);
    }

    getBumpedPayloadInfo(dataSource, lastKnownPayload) {
        let listner = this._listnerFactory.getListner(dataSource);
        if (listner) return listner.getBumpedPayloadInfo(lastKnownPayload);

        throw new Error(`Invalid dataSourceType for getBumpedPayloadInfo. Received DataSourceType=${dataSource}`);
    }
}

export default MaggiListner;
