const absoluteUrl = () => window.location.href;

const relativeUrl = () => window.location.pathname + window.location.search + window.location.hash;

const encodeUrl = (url) => encodeURIComponent(decodeURIComponent(url));

const removeParamFromUrl = (key, sourceURL) => {
    let rtn = sourceURL.split('?')[0];

    let params = [];
    let queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
        params = queryString.split('&');
        for (let i = params.length - 1; i >= 0; i -= 1) {
            let param = params[i].split('=')[0];
            if (param === key) {
                params.splice(i, 1);
            }
        }
        if (params.length) rtn = `${rtn}?${params.join('&')}`;
    }
    return rtn;
};

export {
    absoluteUrl, relativeUrl, encodeUrl, removeParamFromUrl,
};
