import props, { setSpaRecord } from '../../common/props';
import createLogger from '../logging/ngp-logger';
import request from '../utils/request';
import { encodeUrl, relativeUrl } from '../utils/url';

const spaName = props.spaName ?? '';
const logger = createLogger('ngmpAuthLayoutSpaLogger');
const logoutUrl = '/Auth/Logout';
const mergeAccountUrl = '/Auth/InitiateMergeAccount';
const reAutheticateUrl = '/Auth/ReAuthenticate';

const redirect = (path, returnUrl, inid) => {
    if (returnUrl) {
        window.parent.location.href = `${path}?returnUrl=${returnUrl}&inid=${inid}`;
    } else {
        window.parent.location.href = `${path}?inid=${inid}`;
    }
};

const makeTokenCall = (url, successCallback, errorCallback) => {
    request(url)
        .then((response) => {
            if (response.ok) {
                response.text().then((token) => {
                    if (token) successCallback(token);
                    else {
                        errorCallback(500, 'Token is NULL');
                    }
                });
            } else {
                errorCallback(response.status, response.statusText);
            }
        })
        .catch((error) => {
            errorCallback(500, error.message);
        });
};

const revalidateSession = (returnUrl, spa, forceAuth) => {
    const returnUrlQuery = `returnUrl=${encodeUrl(returnUrl || relativeUrl())}`;
    const spaQuery = `spa=${spa || spaName}`;
    const forceAuthQuery = `forceAuthn=${forceAuth ?? true}`;
    window.location.href = `/extspa/home/reauthenticate?${[returnUrlQuery, spaQuery, forceAuthQuery].join('&')}`;
};

const spaLogout = (logoutData) => {
    if (logoutData) {
        const params = new URLSearchParams(logoutData).toString();
        window.location.href = `${logoutUrl}?${params}`;
    } else {
        window.location.href = logoutUrl;
    }
};

const initiateMergeAccount = (returnUrl, inid) => {
    inid = inid ?? '';
    redirect(mergeAccountUrl, returnUrl, inid);
};

const reAuthenticate = (returnUrl) => {
    const returnUrlQp = `returnUrl=${encodeUrl(returnUrl || relativeUrl())}`;
    window.location.href = `${reAutheticateUrl}?${returnUrlQp}`;
};

/**
 * @deprecated Marked for deletion.
 */
const getAccessToken = () => {
    console.warn('Method "getAccessToken" is deprecated and marked for deletion');
    return new Promise((resolve, reject) => {
        const accessToken = props.spaRecord?.ExtSpaToken;
        if (accessToken) resolve(accessToken);
        else reject();
    });
};

const refreshAccessToken = (onSuccess, onError) => {
    return makeTokenCall('/api/spa/GetExtspaToken', (at) => {
        props.spaRecord.ExtSpaToken = at;
        setSpaRecord(props.spaRecord);
        onSuccess(at);
    }, (status, message) => { logger.error(`Error in Token Refresh,Status=${status},Message=${message}`); onError(); });
};

const handleAccessTokenValidationFailure = () => {
    logger.error(`SPA_AUTH TokenValidationFailed; spa=${spaName}`);
    revalidateSession();
};

const notifySpaContainer = (event) => {
    switch (event.action.toLowerCase()) {
        case 'keepalive':
            window.NotifyKeepAlive(event.sender);
            break;
        case 'revalidatesession':
            revalidateSession(event.payload.returnUrl, event.payload.spaName, event.payload.forceAuthn);
            break;
        case 'logout':
            spaLogout(event.payload);
            break;
        case 'initmergeaccount':
            initiateMergeAccount(event.payload.returnUrl, event.payload.inid);
            break;
        default:
            window.parent.postMessage(event, '*');
            break;
    }
};

export {
    makeTokenCall,
    revalidateSession,
    spaLogout,
    getAccessToken,
    handleAccessTokenValidationFailure,
    notifySpaContainer,
    initiateMergeAccount,
    reAuthenticate,
    refreshAccessToken,
};
