import { getSessionHash } from '../helpers/viewbag-record-helper';
import DataHelper from '../helpers/data-Helper';
import { DataSource, StorageMode } from './maggi-constants';
import {
    MaggiInfoModel, MaggiRepoInfoModel, MaggiConfigurationModel, MaggiPayloadInfo,
} from '../models/maggi-models';
import LogHelper from '../helpers/log-Helper';
import props from '../../../common/props';
import request from '../../utils/request';

class MaggiRepository {
    MaggiRepoStorageKeyPrefix = 'spocdata___';
    storageMode = '';
    maggiInfo = new MaggiInfoModel();
    _SyncServerApiUrl = '/api/maggi/syncrepo';
    viewProperties = props;
    constructor() {
        this.storageMode = this.isSessionStorageSupported() ? StorageMode.SESSION : StorageMode.INMEMORY;
        this.cleanUpStaleData();
        this.maggiInfo = new MaggiInfoModel();
        this.initializeConfiguration();
        this.initializeRepoData();
    }

    isSessionStorageSupported() {
        let storage = window.sessionStorage;
        try {
            let testValue = 'NGP_test';
            storage.setItem(testValue, testValue);
            storage.removeItem(testValue);
            return true;
        } catch (e) {
            return false;
        }
    }

    cleanUpStaleData() {
        let sessionHash = getSessionHash();
        if (this.storageMode === StorageMode.SESSION && sessionHash) {
            let sessionStorageKeys = Object.keys(window.sessionStorage);
            sessionStorageKeys.forEach((sessionStorageKey) => {
                if (sessionStorageKey.indexOf(this.MaggiRepoStorageKeyPrefix) === 0
                    && sessionStorageKey !== this.getMaggiRepoStorageKey()) {
                    window.sessionStorage.removeItem(sessionStorageKey);
                }
            });
        }
    }

    getMaggiRepoStorageKey() {
        let sessionHash = getSessionHash();
        if (sessionHash) {
            return (this.MaggiRepoStorageKeyPrefix + sessionHash);
        }
        return null;
    }

    initializeConfiguration() {
        this.maggiInfo.maggiConfiguration = new MaggiConfigurationModel();
        let maggiRecordData = this.viewProperties.maggiRecord;
        if (maggiRecordData && maggiRecordData.maggiConfiguration) {
            this.maggiInfo.maggiConfiguration = maggiRecordData.maggiConfiguration;
        } else {
            // This should not happen. Temporary FallBack
            LogHelper.getMaggiLogger().error('Error Fetching Maggi Record from PropSet');
            this.maggiInfo.maggiConfiguration.spocUrl = 'https://spoc.norton.com/listen';
        }
    }

    initializeRepoData() {
        this.maggiInfo.maggiRepoInfo = new MaggiRepoInfoModel();
        let cachespocData;
        if (this.storageMode === StorageMode.SESSION) {
            cachespocData = this.getRepoInfo();
        } else if (!this.isSessionStorageSupported()) {
            let maggiRecordData = this.viewProperties.maggiRecord;
            let dataFromViewBag = {};
            if (maggiRecordData && maggiRecordData.maggiRepoInfo) dataFromViewBag = maggiRecordData.maggiRepoInfo;
            if (dataFromViewBag) this.maggiInfo.maggiRepoInfo = dataFromViewBag;
            this.setRepoInfo(this.maggiInfo.maggiRepoInfo);
        }
    }

    setRepoInfo(data) {
        // This should never happen - but just a fallback
        if (!this.maggiInfo) this.maggiInfo = new MaggiInfoModel();

        try {
            if (this.storageMode === StorageMode.SESSION) {
                let stringifiedData = DataHelper.tryGetJsonStringify(data);
                this.maggiInfo.maggiRepoInfo = data;
                window.sessionStorage.setItem(this.getMaggiRepoStorageKey(), stringifiedData);
            } else {
                this.maggiInfo.maggiRepoInfo = data;
            }
        } catch (e) {
        }
    }

    setPayloadForDataSource(dataSourceType, payload) {
        let repoInfo = this.getRepoInfo();
        if (!repoInfo) {
            repoInfo = new MaggiRepoInfoModel();
        }
        repoInfo.maggiPayloadInfo = repoInfo.maggiPayloadInfo.filter((ri) => ri.datasourceType !== DataSource[dataSourceType].toString());
        let maggiPayloadInfo = new MaggiPayloadInfo();
        maggiPayloadInfo.datasourceType = DataSource[dataSourceType].toString();
        maggiPayloadInfo.currentPayloadInfo = payload;
        repoInfo.maggiPayloadInfo.push(maggiPayloadInfo);
        this.setRepoInfo(repoInfo);
    }

    getTenantId() {
        return this.viewProperties?.spaRecord?.TenantId ?? '';
    }

    getCurrentPayloadForDataSource(dataSourceType) {
        let repoInfo = this.getRepoInfo();
        if (!repoInfo) {
            repoInfo = new MaggiRepoInfoModel();
        }
        repoInfo.maggiPayloadInfo = repoInfo.maggiPayloadInfo.filter((ri) => ri.datasourceType === DataSource[dataSourceType].toString());
        if (repoInfo.maggiPayloadInfo[0]) return repoInfo.maggiPayloadInfo[0].currentPayloadInfo;
        return undefined;
    }

    getRepoInfo() {
        try {
            let data;
            if (this.storageMode === StorageMode.SESSION) data = DataHelper.tryJsonParse(sessionStorage.getItem(this.getMaggiRepoStorageKey()));
            else data = this.maggiInfo.maggiRepoInfo;
            return data;
        } catch (e) {
            return null;
        }
    }

    syncServerRepo() {
        try {
            let content = DataHelper.tryGetJsonStringify(this.getRepoInfo());
            request(this._SyncServerApiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json,*/*',
                },
                body: content,
            })
                .then((response) => {
                })
                .catch((response) => {
                });
        } catch (e) {
            LogHelper.getMaggiLogger().error(`Exception while MaggiServerSync. Error=${e} StackTrace=${e.stack}`);
        }
    }

    markDirty(repository) {
        try {
            const content = JSON.stringify({
                repository,
            });
            request('/api/spa/MarkDirty', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json,*/*',
                },
                body: content,
            })
                .then((_response) => {
                })
                .catch((_err) => {
                });
        } catch (err) {
            LogHelper.getMaggiLogger().error(`Exception while MarkDirty. Error=${err} StackTrace=${err.stack}`);
        }
    }

    createCORSXhrRequest(method, url) {
        let xhr = new XMLHttpRequest();
        if ('withCredentials' in xhr) {
            // XHR for Chrome/Firefox/Opera/Safari.
            xhr.open(method, url, true);
        } else if (typeof window.XDomainRequest !== 'undefined') {
            // XDomainRequest for IE.
            xhr = new window.XDomainRequest();
            xhr.open(method, url);
        } else {
            // CORS not supported.
            xhr = null;
            // Report an error;
        }
        return xhr;
    }
}

export default MaggiRepository;
