import getXsrfToken from './xsrf';

const getCustomHeaders = () => {
    return {
        'X-Requested-With': 'XMLHttpRequest',
        'X-XSRF-Token': getXsrfToken(),
    };
};

const request = (url, payload = {}) => {
    const { headers } = payload;
    const headerCollection = {
        headers: { ...getCustomHeaders(), ...headers },
    };
    return fetch(url, { ...payload, ...headerCollection });
};

export default request;
